import React, { useEffect } from "react";
import Lottie from "react-lottie";
import * as thinkAnimation from "../../lottie/thinking.json";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { FileArrowUp } from "@phosphor-icons/react";

import { RESUME_ANALYZER_PATH } from "../../config/routes";

interface IResumeAnalyserResults {
  eligibilityScore: number;
  improvements: string[];
  whatToRemove: string[];
  perfectItem: string[];
  name: string;
  summary: string;
  resumeURL: string;
  role: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: thinkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const roleOptions = [
  { label: "React Developer" },
  { label: "Node.js Developer" },
  { label: "Full Stack Developer" },
  { label: "Frontend Developer" },
  { label: "Backend Developer" },
  // Add ruby on rails, python, django, etc
  { label: "Python Developer" },
  { label: "Django Developer" },
  { label: "Ruby on Rails Developer" },
  { label: "Java Developer" },
  { label: "Spring Developer" },
  { label: "C++ Developer" },
  { label: "C# Developer" },
  { label: "Unity Developer" },
  { label: "Game Developer" },
  { label: "Game Designer" },
  { label: "UI/UX Designer" },
  { label: "Graphic Designer" },
  { label: "Product Designer" },
  { label: "Product Manager" },
  { label: "Scrum Master" },
  { label: "Agile Coach" },
  { label: "Business Analyst" },
  { label: "Data Analyst" },
  { label: "Data Scientist" },
  { label: "Machine Learning Engineer" },
  { label: "AI Engineer" },
  { label: "AI Ops" },
  { label: "AI/ML Ops" },
  { label: "AI/ML Researcher" },
];

const loaderMessages = [
  "Analysing your resume",
  "Evaluating your resume",
  "Processing your resume",
  "Thinking",
];

const localStorageKey = "resume-parser-result";

export default function ResumeAnalyser() {
  const [resume, setResume] = React.useState<File | null>(null);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<IResumeAnalyserResults | null>(
    null
  );
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const roleTextRef = React.useRef<string>("React Developer");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setResume(file);
    }
    // Reset the value of the input so that the same file can be uploaded again
    e.target.value = "";
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("resume", resume as Blob);
    formData.append("role", roleTextRef.current);
    setProcessing(true);
    const response = await fetch(RESUME_ANALYZER_PATH, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    setProcessing(false);
    setResult(data);
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  useEffect(() => {
    try {
      const currentResult = localStorage.getItem(localStorageKey);
      if (currentResult) {
        setResult(JSON.parse(currentResult));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const renderContent = () => {
    if (processing) {
      return (
        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-center max-w-7xl w-full min-h-96  rounded-lg">
            <div className="flex flex-col justify-center items-center gap-4">
              <Lottie options={defaultOptions} height={400} width={400} />
              <p className="text-gray-900 text-3xl text-center">
                {
                  loaderMessages[
                    Math.floor(Math.random() * loaderMessages.length)
                  ]
                }
                {/* Add three dots one after other in animated way */}
                <span className="animate-ping">.</span>
                <span className="animate-ping delay-100">.</span>
                <span className="animate-ping delay-200">.</span>
              </p>
            </div>
          </div>
        </div>
      );
    }
    if (result) {
      return (
        <div className="grid grid-cols-2 max-w-7xl mx-auto">
          <div className="py-4">
            <img
              src={result.resumeURL}
              alt="resume"
              className="w-full h-auto shadow-lg border rounded-lg"
            />
          </div>
          <div className="max-w-7xl w-full min-h-96 flex flex-col gap-4 p-4">
            <h3 className="text-4xl font-semibold font-serif">{result.name}</h3>
            <div className="flex gap-2 items-center justify-between">
              <p>
                Applying for the role of{" "}
                <span className="font-semibold">{result.role}</span>
              </p>
              <p className="text-gray-400">
                Eligibility Score: {result.eligibilityScore}
              </p>
            </div>
            <h3 className="text-md text-gray-500 font-serif">
              {result.summary}
            </h3>

            <div>
              <h4 className="text-xl font-semibold py-2">Perfect Items</h4>
              <ul className="list-[square] ml-4">
                {result.perfectItem.map((item, index) => (
                  <li className="font-serif py-1" key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-semibold py-2">Improvements</h4>
              <ul  className="list-[square] ml-4">
                {result.improvements.map((item, index) => (
                  <li className="font-serif py-1" key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-semibold py-2">What to Remove?</h4>
              <ul className="list-[square] ml-4">
                {result.whatToRemove.map((item, index) => (
                  <li className="font-serif py-1" key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    }
    if (resume) {
      return (
        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-center max-w-7xl w-full min-h-96  rounded-lg">
            <div className="flex flex-col justify-center items-center gap-4">
              <p className="text-gray-900 text-3xl text-center">
                Enter the role for which you want to evaluate the resume
              </p>
              <div className="flex items-center flex-wrap justify-center gap-2">
                {roleOptions.map((role, index) => (
                  <div
                    key={role.label}
                    className="flex items-center justify-center px-4 border border-gray-200 rounded-full dark:border-gray-700"
                  >
                    <input
                      type="radio"
                      defaultChecked={roleTextRef.current === role.label}
                      onChange={(e) => {
                        roleTextRef.current = e.target.value;
                      }}
                      id={role.label}
                      value={role.label}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor={role.label}
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {role.label}
                    </label>
                  </div>
                ))}
              </div>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex justify-center">
        <Card className="max-w-7xl w-full min-h-96  ">
          <CardContent className="flex flex-col justify-center items-center gap-4 h-full">
            <FileArrowUp className="w-24 h-24 text-gray-900" />
            <p className="text-gray-900 text-3xl">
              Drag and drop your resume here
            </p>
            <p className="text-gray-900">or</p>
            <input
              type="file"
              className="sr-only"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileUploadRef}
            />
            <Button
              variant={"default"}
              onClick={() => {
                fileUploadRef.current?.click();
              }}
            >
              Browse Your Computer
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <div className="flex-1 py-4">
      <div className="max-w-7xl w-full mx-auto py-2 flex justify-between items-center ">
        <div> 
          <h1 className="text-2xl font-semibold">Resume Analyser</h1>
          <h3 className="text-xs">
            Powered by{" "}
            <a
              href="https://blog.google/technology/ai/google-gemini-ai/?utm_source=varunraj.in"
              target="_blank"
              rel="noreferrer"
            >
              Gemini Vision Pro
            </a>
          </h3>
          </div>
          <div>
            {result && <Button onClick={() => {
              setResult(null);
              setResume(null);
              localStorage.removeItem(localStorageKey);
            }}>Analyse Another Resume</Button>}
          </div>
      </div>
      {renderContent()}
    </div>
  );
}
