import React from 'react'

export default function Footer() {
  return (
    <div className="footer py-2 flex justify-between items-center w-full max-w-7xl mx-auto">
      <div>
          <p className="text-center text-gray-500 text-xs">
          Made with ❤️ by{" "}
          <a
            href="https://twitter.com/zathvarun"
            target="_blank"
            rel="noreferrer"
            className="text-primary"
          >
            Varun Raj Manoharan
          </a>
        </p>
      </div>
      <div>
        <ul className='flex items-center gap-2'>
          <li>
            <a 
              target='_blank'
              rel="noreferrer"
              href="https://genai-tools.featureos.app"
              className='text-gray-600 text-xs'>Feedback</a>
          </li>
          <li>
            <a
              target='_blank'
              rel="noreferrer"
              href="https://featureos.app?utm_source=genai_tools"
              className='text-gray-600 text-xs'>featureOS</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
