import './App.css';
import ResumeAnalyser from './components/Analysers/ResumeAnalyser';
import Footer from './components/Shared/Footer';
import Header from './components/Shared/Header';

function App() {
  return (
    <div className="min-h-screen w-full flex flex-col justify-between">
      <Header />
      <ResumeAnalyser />
      <Footer />
    </div>
  );
}

export default App;
